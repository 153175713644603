<template>
  <div>
    <v-breadcrumbs :items="items" class="pt-0">
        <template v-slot:divider>
            <v-icon :style="{'font-size': '10px'}">fas fa-chevron-right</v-icon>
        </template>
    </v-breadcrumbs>

    <template>
        <div class="text-center">
            <v-dialog
            v-model="cargando_seccion"
            hide-overlay
            persistent
            width="300"
            >
            <v-card
                color="primary"
                dark
            >
                <v-card-text>
                Espere por favor...
                <v-progress-linear
                    indeterminate
                    color="white"
                    class="mb-0"
                ></v-progress-linear>
                </v-card-text>
            </v-card>
            </v-dialog>
        </div>
    </template>

      <div>        
        
        <v-card
        class="mx-auto"
        outlined
        elevation="10"       
        >
        
        <div v-if="buscando">
            <v-progress-linear
            indeterminate
            color="primary"
            ></v-progress-linear>
        </div>

          <v-data-table
            :headers="headers"
            :items="cotizaciones"
            :search="search"
          >
            <template v-slot:top>
              <v-toolbar
                flat
              >                 
                  <v-toolbar-title>Cotizaciones</v-toolbar-title>
                  <v-divider
                    class="mx-4"
                    inset
                    vertical
                  ></v-divider>
                  <v-spacer>
                  </v-spacer>
                    <v-text-field
                    v-model="search"
                    append-icon="mdi-magnify"
                    label="Buscar"
                    single-line
                    hide-details
                    class="col-md-4 mr-6"
                  ></v-text-field>

                  <v-dialog
                    v-model="dialog"
                    fullscreen
                    hide-overlay
                    transition="dialog-bottom-transition"
                    scrollable
                  >
                 
                      <v-card tile>
                        <v-toolbar
                          flat
                          dark
                          color="primary"
                        >
                          <v-btn
                            icon
                            dark
                            @click="dialog = false"
                          >
                            <v-icon>fas fa-times</v-icon>
                          </v-btn>
                          <v-toolbar-title>Cotización</v-toolbar-title>
                          <v-spacer></v-spacer>
                          <v-toolbar-items>
                            <v-btn
                              dark
                              text
                              v-if="verificarPermiso(23)"
                              @click="descargarPdf(detalleCotizacion.view_descarga)"
                            >
                              PDF
                            </v-btn>
                          </v-toolbar-items>
                          <v-menu
                            bottom
                            right
                            offset-y
                          >
                            <template v-slot:activator="{ on, attrs }">
                              <v-btn
                                dark
                                icon
                                v-bind="attrs"
                                v-on="on"
                              >
                                <v-icon>fas fa-ellipsis-v</v-icon>
                              </v-btn>
                            </template>
                            <v-list>
                              <v-list-item
                                v-for="(item, i) in items"
                                :key="i"
                                @click="() => {}"
                              >
                                <v-list-item-title>{{ item.title }}</v-list-item-title>
                              </v-list-item>
                            </v-list>
                          </v-menu>
                        </v-toolbar>
                          <v-card-text>
                            <v-row justify="center" align="center">
                            <v-col cols="9">

                                  <cotizacion-view :cotizacion="detalleCotizacion"></cotizacion-view>
                           
                                <v-divider></v-divider>

                              </v-col>
                            </v-row>
                          </v-card-text>
                          <div style="flex: 1 1 auto;"></div>
                      </v-card>
                   
                  </v-dialog>


                  <v-dialog v-model="dialogDelete" max-width="550">
                    <v-card>
                      <v-toolbar
                        color="primary"
                        dark
                      >Eliminar
                      </v-toolbar>
                      <v-card-text class="text-h6 pa-10">Está seguro que desea eliminar la cotizacion?</v-card-text>
                      <v-card-actions  class="justify-end">
                        <v-spacer></v-spacer>
                        <v-btn color="darken-1" text @click="closeDelete">Cancelar</v-btn>
                        <v-btn color="darken-1" class="primary" primary text @click="deleteItemConfirm">Confirmar</v-btn>
                        <v-spacer></v-spacer>
                      </v-card-actions>
                    </v-card>
                  </v-dialog>

              </v-toolbar>
            </template>

            <template v-slot:item.tipo="props">
              <template>
                    
                    {{props.item.tipo == 10 ? 'Opciones' : 'Rango'}}
                     
                    
              </template>                
            </template>

            <template v-slot:item.estado="{ item }">
              <v-chip
                :color="item.estado == 0 ? 'error' : 'success' "
                dark
              >
                {{ item.estado == 0 ? 'PEND.CONFIRMACIÒN' : 'CONFIRMADO'}}
              </v-chip>
            </template>

            <template v-slot:item.estado_pedido_id="{ item }">
              <v-chip
                :color="item.estado_pedido_id == 0 ? 'error' : 'success' "
                dark
              >
                {{ item.estado_pedido_id == 0 ? 'PENDIENTE' : 'CONFIRMADO'}}
              </v-chip>
            </template>

            <template v-slot:item.actions="{ item }">              
             
              <template>
                <div class="text-center">
                  <v-menu offset-y>
                    
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        color="secondary"
                        dark
                        v-bind="attrs"
                        v-on="on"
                        small
                      >
                        <v-icon color=""> fas fa-cogs</v-icon>
                      </v-btn>
                    </template>
                    <v-list>

                      <v-list-item
                      @click="openModalCotizacion(item,'cotizacion_view')"
                       v-if="verificarPermiso(21)"
                      >
                        <v-list-item-title>
                          <v-icon color="light-blue darken-2"> fas fa-file-invoice </v-icon>
                          Ver Cotizaciòn
                        </v-list-item-title>
                      </v-list-item>

                      <v-list-item
                      @click="openModalCotizacion(item,'pedido_view')"
                       v-if="verificarPermiso(21) && item.estado == 1"
                      >
                        <v-list-item-title>
                          <v-icon color="light-blue darken-2"> fas fa-luggage-cart </v-icon>
                          Ver Pedido
                        </v-list-item-title>
                      </v-list-item>

                      <v-list-item
                      @click="confirmarCotizacion(item.id)"
                       v-if="verificarPermiso(21)"
                      >
                        <v-list-item-title>
                          <v-icon color="light-green darken-2"> fas fa-check </v-icon>
                          Confirmar Cotización
                        </v-list-item-title>
                      </v-list-item>

                      <v-list-item                       
                        v-if="verificarPermiso(22)"
                        @click="deleteItem(item.id)"
                      >
                        <v-list-item-title>                          
                          <v-icon
                          color="red darken-2"
                          > fas fa-trash </v-icon>
                          Eliminar
                        </v-list-item-title>
                      </v-list-item>

                    </v-list>
                  </v-menu>
                </div>
              </template>

            </template>

          </v-data-table>          
        </v-card>
      </div>
     
  </div>
</template>

<script>
import {mapMutations} from 'vuex'
import axios from 'axios'
import * as CONFIG from '@/config/config.js'
import * as checkPermiso from '@/config/checkPermisos.js'
import CotizacionView from '@/components/CotizacionView.vue'
import VueHtml2pdf from 'vue-html2pdf'
export default {
  name:'cotizaciones',
  data(){
    return {      
      items: [
                {
                    text: 'Inicio',
                    disabled: false,
                    exact: true,
                    to: {name: 'Home'}
                },
                {
                    text: 'Cotizaciones',
                    disabled: true,
                }
            ],      
      buscando: false,
      search:'',
      headers:[        
        { text: 'Nro Cotización', value: 'cotizacion_nro'},
        { text: 'Descripción', value: 'descripcion' },
        { text: 'Cliente', value: 'cliente_nombre' },
        { text: 'Producto', value: 'producto_nombre' },
        { text: 'E.Cotización', value: 'estado' },
        { text: 'Estado', value:'estado_cotizacion',align: ' d-none'},
        //{ text: 'E.Pedido', value: 'estado_pedido_id' },
        { text: 'Acciones', value: 'actions', sortable: false,width:'10%' }
      ],
      cotizaciones:[],
      cotizacion:{},
      dialog:false,
      tipo:'',
      dialogDelete:false,
      itemOpciones:[
        {tipo: 10,nombre:'Opciones'},
        {tipo: 20,nombre:'Rango'}
      ],
      detalleCotizacion:[],
      cargando_seccion:false,
    }
  },
  components:{
    CotizacionView,
    VueHtml2pdf
  },
  methods:{
     ...mapMutations(['setAlert','SnackbarShow']),
    verificarPermiso(permiso){
          return checkPermiso.tienePermiso(permiso);
    },
    generatePDF() {
      this.$refs.html2Pdf.generatePdf();
    },
    getCotizaciones() {

      return new Promise((resolve, reject) => {                    
            this.buscando = true;     
            axios.get(CONFIG.urlApi+'cotizaciones/getCotizaciones',{})
          .then(resp => {
              
              this.cotizaciones = resp.data.data;
              this.buscando = false;
  
          })
          .catch(err => {                  
              
              this.SnackbarShow({
                      tipo: 'error',
                      mensaje: err.statusText
                  });
              this.buscando = false;

          });
                    
      });
    },
    openModalCotizacion(data,tipo) {

      this.detalleCotizacion = [];           
      this.buscando = true;
      return new Promise((resolve, reject) => {
        this.buscando = true;     
        axios.get(CONFIG.urlApi+'cotizaciones/datosCotizacionView/'+data.id+'/'+tipo,{})
            .then(resp => {
          
                this.buscando = false;
                this.detalleCotizacion = resp.data.data;
                this.dialog = true;

            })
            .catch(err => {
              
                this.buscando = false;
                this.SnackbarShow({
                  tipo: 'error',
                  mensaje: err.statusText
                });
                
            });

              
      });
            
            
    },
    descargarPdf(tipo){

     let cotizacion_id =  this.detalleCotizacion.data_cotizacion.id

      return new Promise((resolve, reject) => {
          this.cargando_seccion = true;

          axios({url: CONFIG.urlApi+'cotizaciones/datosCotizacionView/'+cotizacion_id+'/'+tipo,data:{},method: 'GET',
            responseType: 'blob'})
          
          .then(resp => {
            this.cargando_seccion = false;
              const url = window.URL.createObjectURL(new Blob([resp.data]));
              const link = document.createElement('a');
              link.href = url;
              link.setAttribute('download', tipo+'_'+cotizacion_id+'.pdf');
              document.body.appendChild(link);
              link.click();

          })
          .catch(err => {
            this.cargando_seccion = false;
            this.SnackbarShow({
                        tipo: 'error',
                        mensaje: err.statusText
                    });
                reject(err)
          });
          
      })
            
    },
    deleteItem (item_id) {
        this.cotizacion.id = item_id;
        this.dialogDelete = true
    },
    deleteItemConfirm () {
        
      return new Promise((resolve, reject) => {                    
            this.buscando = true;       
            axios.delete(CONFIG.urlApi+'cotizaciones/eliminar/'+this.cotizacion.id, {})
          .then(resp => {                
             
              this.cotizacion.id = {};

              this.SnackbarShow({
                                    tipo: 'success',
                                    mensaje: resp.data.statusText
                                });

              this.getCotizaciones();
              this.buscando = false;
              
          })
          .catch(err => {                  
              
              this.SnackbarShow({
                      tipo: 'error',
                      mensaje: err.statusText
                  });
              this.buscando = false;

          });
            this.closeDelete();
                    
      });
    },
    confirmarCotizacion (cotizacion_id) {
        
      return new Promise((resolve, reject) => {                    
            this.buscando = true;       
            axios.put(CONFIG.urlApi+'cotizaciones/confirmar/'+cotizacion_id, {})
          .then(resp => {                
             
              this.SnackbarShow({
                                    tipo: 'success',
                                    mensaje: resp.data.statusText
                                });

              this.getCotizaciones();
              this.buscando = false;
              
          })
          .catch(err => {                  
              
              this.SnackbarShow({
                      tipo: 'error',
                      mensaje: err.statusText
                  });
              this.buscando = false;

          });
                    
      });
    },
    closeDelete () {
      this.dialogDelete = false
    },
  },
  watch: {
      dialogDelete (val) {
        val || this.closeDelete()
      },
  },
  mounted(){
    this.getCotizaciones();
  }
  
}
</script>
